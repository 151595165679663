select {
  appearance: none;
  color: $light-color;
  border: $border-width solid
    desaturate(lighten($bg-color-inverse-light, 10%), 2%);
  padding: $control-padding-y $control-padding-x;
  outline: none;
  transition: border-color 0.3s ease;
  width: 100%;

  &:focus {
    border-color: desaturate(lighten($bg-color-inverse-light, 15%), 2%);
  }

  &:not([multiple]):not([size]) {
    background: $bg-color-inverse-light
      url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23667189'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E")
      no-repeat right 0.35rem center / 0.4rem 0.5rem;
    padding-right: $control-icon-size + $control-padding-x;
  }
}
