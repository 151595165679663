$base-font-family: "IBM Plex Sans", -apple-system, system-ui, BlinkMacSystemFont,
  "Segoe UI", Roboto, sans-serif;
$primary-color: #df5157;
$bg-color-primary: #ff5656;
$bg-color-inverse: #1b1c23;
$bg-color-inverse-light: desaturate(lighten($bg-color-inverse, 3%), 2%);
$border-color-translucent: rgba(255, 255, 255, 0.45);

@import "node_modules/spectre.css/src/variables";
@import "node_modules/spectre.css/src/normalize";
@import "node_modules/spectre.css/src/mixins";
@import "node_modules/spectre.css/src/base";
@import "node_modules/spectre.css/src/typography";
@import "node_modules/spectre.css/src/layout";
@import "node_modules/spectre.css/src/utilities";
@import "node_modules/spectre.css/src/modals";
@import "node_modules/spectre.css/src/media";
@import "node_modules/spectre.css/src/labels";
@import "node_modules/spectre.css/src/tables";

@include padding-variant(3, $unit-3);
@include padding-variant(4, $unit-4);
@include padding-variant(5, $unit-5);
@include padding-variant(6, $unit-6);
@include margin-variant(3, $unit-3);
@include margin-variant(4, $unit-4);
@include margin-variant(5, $unit-5);
@include margin-variant(6, $unit-6);

@include bg-color-variant(".bg-light", $light-color);
@include bg-color-variant(".bg-inverse", $bg-color-inverse);
@include bg-color-variant(".bg-inverse-light", $bg-color-inverse-light);
@include text-color-variant(".text-muted", rgba($body-font-color, 0.75));
@include text-color-variant(".text-inverse-muted", rgba($light-color, 0.75));

@import "core";
@import "checkbox";
@import "buttons";
@import "card";
@import "footer";
@import "forms";
@import "header";
@import "icons";
@import "data-privacy-notice";
@import "menu-category";
@import "locations";

.label.label-inverse {
  @include label-variant(
    rgba($light-color, 0.75),
    lighten($bg-color-inverse, 10%)
  );
}
