.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  @extend .bg-light;
  @extend .py-2;
  z-index: 2;

  & .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .footer-button {
    width: 100%;

    & span {
      flex: 1;
    }
  }
}

.footer-placeholder {
  height: 60px;
}
