$checkbox-size: 18px;
$checkbox-bg-color: desaturate(lighten($bg-color-inverse, 10%), 3%);

.checkbox,
.checkbox:active {
  display: none;
}

.checkbox-label {
  width: 35px;
  display: block;
  position: relative;
  height: $checkbox-size;
  border-radius: $checkbox-size / 2;
  background-color: $checkbox-bg-color;

  margin-right: 0.6rem;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    position: absolute;
    height: $checkbox-size;
    width: $checkbox-size;
    border-radius: $checkbox-size / 2;
    top: 0;
    left: 0;
    right: auto;
    background: desaturate(lighten($bg-color-inverse, 65%), 3%);
    transition: left 0.2s ease;
  }
}

.checkbox:checked {
  & + .checkbox-label {
    background-color: $primary-color;

    &:before {
      left: calc(100% - #{$checkbox-size});
    }
  }
}
