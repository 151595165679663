header {
  color: $light-color;
  justify-content: space-between;
  background-color: $bg-color-inverse;
  background-image: url(/images/hero-bg.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  & .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @extend .p-6;

    & .navbar-left {
      z-index: 1;
    }

    & .navbar-center {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 0;
    }

    & .navbar-right {
      display: flex;
      position: relative;
      text-align: right;

      & .navbar-right-icon {
        font-size: 0.9rem;
        margin-left: 0.6rem;
      }

      & .navbar-right-links {
        top: 100%;
        right: $unit-4;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      & .navbar-right-link-active {
        &::after {
          content: " ";
          position: absolute;
          width: 2px;
          left: 100%;
          height: $unit-4;
          background-color: $primary-color;
        }
      }
    }
  }

  & .hero {
    min-height: 350px;
  }
}
