.material-icons {
  color: inherit;
  font-size: 24px;
  line-height: inherit;

  &.icon-active {
    color: $primary-color;
  }

  &.icon-secondary {
    opacity: 0.75;
  }

  &.icon-inverse {
    color: $light-color;
  }
}
