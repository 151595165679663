.data-privacy-notice {
  font-family: $mono-font-family;
  font-size: 13px;
  color: rgba($light-color, 0.8);
  background-color: $bg-color-primary;
  position: fixed;
  padding: 1rem;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 450px;
  z-index: 1000;

  & .data-privacy-notice-buttons {
    margin-top: 0.5rem;
    display: flex;

    & span {
      color: $light-color;
      cursor: pointer;

      &:hover {
        color: rgba($light-color, 0.8);
      }
    }
  }
}
