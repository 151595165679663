.locations {
  background-color: rgba($bg-color-inverse, 0.65);
  backdrop-filter: blur(10px);
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-style: solid;
  border-color: $border-color-translucent;

  & .column {
    padding: 1rem 1.2rem 1.5rem;
    border-style: solid;
    border-color: $border-color-translucent;
    border-width: 0;

    &:not(:last-child) {
      border-right-width: 1px;
    }

    @media (max-width: $size-md) {
      &:nth-child(even) {
        border-right-width: 0;
      }

      &:nth-child(1),
      &:nth-child(2) {
        border-bottom-width: 1px;
      }
    }

    @media (max-width: $size-sm) {
      &:nth-child(3) {
        border-bottom-width: 1px;
      }
    }
  }
}
