html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

b,
strong {
  font-weight: 500;
}

small {
  font-size: $font-size-sm;
}

a:visited {
  color: inherit;
}

a[aria-current="page"] {
  color: $primary-color;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.text-lead {
  font-size: 2rem;
  line-height: 2rem;
}

.text-slim {
  font-weight: 300;
}

.text-bold {
  font-weight: 500;
}

.text-spacing {
  letter-spacing: 3px;
}

.sticky-header {
  @extend .p-sticky;
  z-index: 1;
  top: 0;
  background-color: rgba($bg-color-inverse, 0.8);
  backdrop-filter: blur(10px);
}

.full-height {
  min-height: 100vh;
}
