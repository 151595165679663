.btn {
  display: inline-flex;
  align-items: center;
  color: inherit;
  cursor: pointer;
  font-weight: 500;
  min-height: 2rem;
  padding: 0 0.8rem;
  border-style: solid;
  border-width: 1px;
  border-color: inherit;
  background-color: transparent;
  transition: color 0.3s ease;

  &.btn-primary {
    background-color: $light-color;
    color: $primary-color;
  }

  &.btn-light {
    border-color: $light-color;
  }

  &:hover,
  &:focus {
    border-color: $primary-color;
    color: darken($primary-color, 5);
    text-decoration: none;
    box-shadow: none;
    outline: none;
  }

  &.btn-link {
    border-width: 0px;
    display: inline-flex;
    align-items: center;
  }

  & .btn-arrow {
    transform: rotate(180deg);
    margin-left: 0.6rem;
  }
}
