.menu-category-card {
  height: 100%;
}

.menu-category-content {
  cursor: pointer;
  display: block;
  padding: 1.2rem;
  transition: background-color 0.3s ease;

  &:hover {
    text-decoration: none;
    background-color: rgba($primary-color, 0.5);
  }
}
