.card {
  position: relative;
  background: $bg-color-inverse-light;
  overflow: hidden;

  &.card-rounded {
    border-radius: 5px;
  }

  &.card-primary {
    @extend .bg-primary;
  }
}
